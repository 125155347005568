import { parse } from "date-fns"

// validates that the provided string is roughly "YYY-MM-DD" and returns a date or "false" if not valid
export const getValidatedDateFromDateString = (dateString: string): Date | false => {
  // Check if the string is empty.
  if (dateString === "") {
    return false
  }

  // Check if the string is in the correct format.
  const regex = /^\d{4}-\d{2}-\d{2}$/
  if (!regex.test(dateString)) {
    return false
  }

  // Parse the date string into a Date object.
  try {
    const date = parse(dateString, "yyyy-MM-dd", new Date())
    // Check if the date is valid.
    // @ts-expect-error  (this signature doesn't match the runtime implementation... there is a lot of useful coercion captured in `isNaN`)
    if (isNaN(date)) {
      return false
    }

    // Return the date if the date is valid.
    return date
  } catch {
    return false
  }
}
export const getDateFromDateString = (dateString: string): Date => {
  return parse(dateString, "yyyy-MM-dd", new Date())
}
