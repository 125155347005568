import { FC } from "react"
import { AssetCategory } from "../graphql/generated/client-types-and-hooks"

type assetCategoryMappedItem = { label: string; sortOrder: number }
const assetCategoryMapping: { [key in AssetCategory]: assetCategoryMappedItem } = {
  [AssetCategory.Other]: { label: "Other", sortOrder: 1 },
  [AssetCategory.HeavyEquipment]: { label: "Heavy Equipment", sortOrder: 2 },
  [AssetCategory.SmallEquipment]: { label: "Small Equipment", sortOrder: 3 },
  [AssetCategory.Vehicles]: { label: "Vehicles", sortOrder: 4 },
  [AssetCategory.Trailers]: { label: "Trailers", sortOrder: 5 },
  [AssetCategory.Tools]: { label: "Tools", sortOrder: 6 },
  [AssetCategory.Electronics]: { label: "Electronics", sortOrder: 6 },
}

export type AssetCategoryOption = {
  value: AssetCategory
  label: assetCategoryMappedItem["label"]
}

export const getAssetCategoryOption = (category: AssetCategory): AssetCategoryOption => {
  const defaultCategory = AssetCategory.Other
  if (category in AssetCategory) {
    return {
      value: AssetCategory[category],
      label: assetCategoryMapping[category].label,
    }
  }
  return {
    value: defaultCategory,
    label: assetCategoryMapping[defaultCategory].label,
  }
}

export const assetCategoryOptions: AssetCategoryOption[] = Object.keys(AssetCategory)
  .filter((key) => isNaN(Number(key)))
  .map((key) => getAssetCategoryOption(key as AssetCategory))
  .sort((a, b) => {
    if (assetCategoryMapping[a.value].sortOrder < assetCategoryMapping[b.value].sortOrder) return -1
    else return 1
  })

type ShowForCategoryProps = {
  category: AssetCategory
  showFor: AssetCategory[]
  children: React.ReactNode
}

export const ShowForCategory: FC<ShowForCategoryProps> = ({ category, showFor, children }) => {
  if (showFor.includes(category)) return children
  return null
}
