import { TextField as MuiTextField, TextFieldProps } from "@mui/material"
import { useField } from "formik"
import { formatMoney } from "../../helpers/formatMoney"
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber"

export const TextField = (props: TextFieldProps & { name: string }) => {
  const [{ value = "" }, { touched, error }, { setValue }] = useField(props.name)
  const displayError = touched && error && !props.disabled

  return (
    <MuiTextField
      {...props}
      size="small"
      value={value}
      onChange={(e) => {
        if (props.onChange) props.onChange(e)
        const value = e.target.value
        if (props.type === "tel") {
          setValue(formatPhoneNumber(value))
        } else if (props.type === "money") {
          const append = value.at(-1) === "." ? "." : ""
          const decimalPlaces = value.split(".").at(1)?.length || 0
          const valueAsNumber = Number(value.replace(/[^0-9.-]+/g, ""))
          setValue(`${formatMoney(valueAsNumber, Math.min(decimalPlaces, 2))}${append}`)
        } else if (props.type === "number" && value !== "") {
          setValue(parseFloat(value))
        } else {
          setValue(value)
        }
      }}
      helperText={displayError ? error : <>&nbsp;</>}
      error={!!displayError}
      FormHelperTextProps={{ className: "my-0.5" }}
    />
  )
}
