export type ScaleMethod = "crop" | "contain"

const getImagePositioningValues = (
  scaleMethod: ScaleMethod,
  image: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
) => {
  // crop will scale it down and then crop the edges
  if (scaleMethod === "crop") {
    if (image.width > image.height) {
      image.width = image.width * (maxWidth / image.height)
      image.height = maxHeight
    } else {
      image.height = image.height * (maxHeight / image.width)
      image.width = maxWidth
    }

    return {
      imageWidth: image.width,
      imageHeight: image.height,
    }
  }

  // contain will scale it down and keep the aspect ratio
  const xScaleFactor = maxWidth / image.width
  const yScaleFactor = maxHeight / image.height
  const scaleFactor = Math.min(xScaleFactor, yScaleFactor)

  const imageWidth = image.width * scaleFactor
  const imageHeight = image.height * scaleFactor

  return {
    imageWidth,
    imageHeight,
  }
}

export const getDataUrlForImage = (
  file: File,
  onImageComplete: (dataUrl: string) => void,
  maxWidth = 600,
  maxHeight = 600,
  scaleMethod: ScaleMethod = "crop",
  onError?: (error: Error) => void
) => {
  // Ensure it's an image
  if (!file.type.match(/image.*/)) {
    if (onError) onError(new Error("File must be an image"))
    return
  }

  // Load the image
  const reader = new FileReader()
  reader.onload = (readerEvent) => {
    const image = new Image()
    image.src = readerEvent.target?.result as string
    image.onload = () => {
      // Resize the image
      const canvas = document.createElement("canvas")
      canvas.width = maxWidth
      canvas.height = maxHeight
      const { imageWidth, imageHeight } = getImagePositioningValues(scaleMethod, image, maxWidth, maxHeight)

      // center the image
      const xPosition = -1 * ((imageWidth - maxWidth) / 2)
      const yPosition = -1 * ((imageHeight - maxHeight) / 2)

      const TwoDContext = canvas.getContext("2d")
      if (TwoDContext) {
        TwoDContext.drawImage(image, xPosition, yPosition, imageWidth, imageHeight)
        const dataUrl = canvas.toDataURL("image/webp")
        onImageComplete(dataUrl)
      }
    }
  }
  reader.readAsDataURL(file)
}

export const getDataUrlForImageAsync = (
  file: File,
  maxWidth?: number,
  maxHeight?: number,
  scaleMethod: ScaleMethod = "crop"
): Promise<string> => {
  return new Promise((resolve, reject) => {
    getDataUrlForImage(
      file,
      (dataUrl) => resolve(dataUrl),
      maxWidth,
      maxHeight,
      scaleMethod,
      (error) => reject(error)
    )
  })
}
