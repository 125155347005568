import { Chip } from "@mui/material"
import { useField } from "formik"
import Link from "next/link"
import { FC, ReactNode } from "react"

import { classNames } from "../../helpers/classNames"
import { Label } from "../Elements"

export type ChipSelectOption = {
  value: string
  label: string
  description?: string
  descriptionLink?: string
}

export type ChipSelectProps = {
  name: string
  options: ChipSelectOption[]
  required?: boolean
  label?: string
  labelEl?: ReactNode
  hideDescription?: boolean
}

type ButtonProps = {
  option: ChipSelectOption
  isSelected: boolean
  onClick: (option: ChipSelectOption["value"]) => void
}

const ChipSelectButton: FC<ButtonProps> = ({ option, isSelected, onClick }) => {
  return option ? (
    <Chip
      key={option.value}
      className={classNames(
        "rounded-full py-5 px-2 text-[14px]",
        isSelected ? "bg-gray-800 text-white hover:bg-gray-800" : "bg-gray-100 text-gray-800"
      )}
      onClick={() => onClick(option.value)}
      label={option.label}
    />
  ) : null
}

export const ChipSelect: FC<ChipSelectProps> = ({
  name,
  options,
  label,
  labelEl,
  hideDescription,
  required = false,
}) => {
  const [field, _meta, helpers] = useField<ChipSelectOption["value"]>(name)
  const selectedOption = options.find((option) => option.value === field.value)

  return (
    <div>
      {labelEl ||
        (label && (
          <Label isRequired={required} className="pb-3">
            {label}
          </Label>
        ))}
      <div className="flex flex-wrap gap-x-1 gap-y-2 mb-4">
        {options.map((option) => (
          <ChipSelectButton
            key={option.value}
            option={option}
            isSelected={field.value === option.value}
            onClick={helpers.setValue}
          />
        ))}
      </div>
      {!hideDescription && selectedOption?.description && (
        <p className="text-gray-400 h-16 text-sm">
          {selectedOption.description}{" "}
          {selectedOption?.descriptionLink && (
            <Link href={selectedOption.descriptionLink}>
              <span className="underline">More details.</span>
            </Link>
          )}
        </p>
      )}
    </div>
  )
}
