import { Switch as HeadlessUISwitch } from "@headlessui/react"
import { FC } from "react"
import { classNames } from "../../helpers/classNames"

type SwitchProps = {
  checked: boolean
  setChecked: (v: boolean) => void
}

export const DeprecatedSwitch: FC<SwitchProps> = ({ checked, setChecked }) => {
  return (
    <HeadlessUISwitch
      checked={checked}
      onChange={(checked: boolean) => {
        setChecked(checked)
      }}
      className={classNames(
        checked ? "bg-blue-600" : "bg-gray-200",
        "relative inline-flex h-6 w-11 items-center rounded-full shrink-0"
      )}
    >
      <span
        className={classNames(
          checked ? "translate-x-6" : "translate-x-1",
          "inline-block h-4 w-4 transform rounded-full bg-white transition"
        )}
      />
    </HeadlessUISwitch>
  )
}
