import { useEffect, useRef, useState } from "react"

export const useInView = () => {
  const [isInView, setIsInView] = useState(false)
  const [hasBeenInView, setHasBeenInView] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let observerRefValue: Element | null = null

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setHasBeenInView(true)
        setIsInView(entry.isIntersecting)
      },
      {
        threshold: 1,
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
      observerRefValue = ref.current
    }

    return () => {
      if (ref && observerRefValue) observer.unobserve(observerRefValue)
    }
  }, [ref])

  return { hasBeenInView, isInView, ref }
}
