import axios, { AxiosError } from "axios"
import { useState } from "react"
import { getAssetDocumentsSignedPutUrl, getFileUploadSignedPutUrl, getProjectDocumentSignedPutUrl, getUserDocumentsSignedPutUrl } from "../data/api"
import { errorSnack } from '../components/Notistack/ThemedSnackbars'

export const fileUpload = async (
  key: string,
  type: string,
  file: Blob
): Promise<
  | {
    expiration: Date
    fileId: string
    objectKey: string
    url: string
  }
  | undefined
> => {
  try {
    const { expiration, fileId, objectKey, url } = await getFileUploadSignedPutUrl(key, type)

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      body: file
    })

    return { expiration: new Date(expiration), fileId, objectKey, url }
  } catch (e) {
    errorSnack("There was a problem uploading your file. Please try again.")
  }
}

export const assetDocumentUpload = async (
  key: string,
  type: string,
  file: Blob,
  assetId: string,
  status?: string,
  expiresAt?: Date | string
): Promise<
  | {
    expiration: Date,
    fileId: string,
    objectKey: string,
    url: string,
    assetId: string,
    status?: string,
    expiresAt?: Date | string
  }
  | undefined
  > => {
    try{
      const { expiration, fileId, objectKey, url } = await getAssetDocumentsSignedPutUrl(key, type, assetId, status, expiresAt)

      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": type
        },
        body: file
      })

      return { expiration: new Date(expiration), fileId, objectKey, url, assetId, status, expiresAt }
  } catch (e) {
    errorSnack("There was a problem uploading your file. Please try again.")
  }
}

export const userDocumentUpload = async (
  key: string,
  type: string,
  file: Blob,
  userId: string,
  status?: string,
  expiresAt?: Date | string
): Promise<
  | {
    expiration: Date,
    fileId: string,
    objectKey: string,
    url: string,
    userId: string,
    status?: string,
    expiresAt?: Date | string
  } 
  | undefined
  > => {
  try {
    const { expiration, fileId, objectKey, url } = await getUserDocumentsSignedPutUrl(key, type, userId, status, expiresAt)

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": type
      },
      body: file
    })
    
    return { expiration: new Date(expiration), fileId, objectKey, url, userId, status, expiresAt }
  } catch (e) {
     errorSnack("There was a problem uploading your file. Please try again.")
  }
}

export const projectDocumentUpload = async (
  key: string,
  type: string,
  file: Blob,
  projectId?: string,
  status?: string,
  expiresAt?: Date | string
): Promise<
  | {
    expiration: Date
    fileId: string
    objectKey: string
    url: string
    projectId?: string
    status?: string
    expiresAt?: Date | string
  }
  | undefined
> => {
  try {
    const { expiration, fileId, objectKey, url } = await getProjectDocumentSignedPutUrl(key, type, projectId, status, expiresAt)

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      body: file
    })

    return { expiration: new Date(expiration), fileId, objectKey, url, projectId, status, expiresAt }
  } catch (e) {
    errorSnack("There was a problem uploading your file. Please try again.")
  }
}

export const useFileUpload = () => {
  const [abortController, setAbortController] = useState<AbortController>()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setError] = useState<Error>()
  const [uploaded, setUploaded] = useState(false)

  return {
    uploadProgress,
    uploaded,
    uploadError,
    uploadToS3: async (signedUrl: string, file: Blob) => {
      try {
        const controller = new AbortController()
        setAbortController(controller)

        await axios.put(signedUrl, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (e) => setUploadProgress(e?.total ? Math.round((e.loaded / e.total) * 100) / 100 : 0),
          signal: controller.signal,
        })

        setUploaded(true)
      } catch (error) {
        if (error instanceof AxiosError) setError(error.response?.data)
        else if (error instanceof Error) setError(error)
        else setError(new Error("something went wrong"))
      }
    },
    cancelUpload: async () => {
      abortController && abortController.abort()
    },
  }
}
