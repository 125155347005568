import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import { Field, FieldProps } from "formik"
import { FC } from "react"

interface DropdownProps {
  items: DropDownMUIItem[]
  fieldName: string
  label: string
}

export type DropDownMUIItem = {
  value: string | number
  label: string
  disabled?: boolean
  isDefault?: boolean
  color?: string
}

export const DropDownMUI: FC<DropdownProps> = ({ items, fieldName, label }) => {
  return (
    <Field name={fieldName}>
      {({ field, form, meta }: FieldProps) => (
        <FormControl fullWidth error={Boolean(meta.error) && Boolean(meta.touched)}>
          <InputLabel size="small">{label}</InputLabel>
          <Select label={label} value={field.value} onChange={(e) => form.setFieldValue(field.name, e.target.value)}>
            {items.map((item) => (
              <MenuItem
                key={item.value.toString()}
                value={item.value}
                disabled={item.disabled}
                style={item.isDefault ? { color: "gray" } : {}}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {meta.touched && Boolean(meta.error) ? <FormHelperText>Please select an option</FormHelperText> : null}
        </FormControl>
      )}
    </Field>
  )
}
