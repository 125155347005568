import { FC, useState } from "react"
import { DropDownMUI, DropDownMUIItem } from "../../../DropDownMUI"
import { FormRow } from "../../../FormRow"
import { StandardCheckbox } from "../../../Formik/StandardCheckbox"
import { DeprecatedSwitch } from "../../../deprecated/Switch"
import { InventoryIntervalOptions } from "./InventoryIntervalOptions"

export const handleInventoryRequirementsSubmission = (values: {
  photoRequired?: boolean
  intervalInSeconds?: string
}) => ({ ...values, intervalInSeconds: values.intervalInSeconds ? +values.intervalInSeconds : undefined })

export const InventoryRequirementsForm: FC<{ isCheckedByDefault?: boolean }> = ({ isCheckedByDefault = true }) => {
  const options: DropDownMUIItem[] = [
    { value: "", label: "None", color: "text-gray-400" },
    ...Object.keys(InventoryIntervalOptions).map((key) => ({
      value: InventoryIntervalOptions[key].toString(),
      label: key,
    })),
  ]

  const [isEnabled, setIsEnabled] = useState(isCheckedByDefault)

  return (
    <>
      <FormRow textLabel="Activate Inventory Checklist">
        <DeprecatedSwitch checked={isEnabled} setChecked={setIsEnabled} />
      </FormRow>
      {isEnabled && (
        <div className="mt-12">
          <FormRow textLabel="Inventory Schedule Interval">
            <DropDownMUI
              items={options}
              fieldName="inventoryRequirements.intervalInSeconds"
              label="Choose inventory frequency"
            />
          </FormRow>
          <FormRow textLabel="Photo Required?">
            <StandardCheckbox required={false} name="inventoryRequirements.photoRequired" />
          </FormRow>
        </div>
      )}
    </>
  )
}
