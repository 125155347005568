import { FC, ReactNode } from "react"
import { classNames } from "../../helpers/classNames"

type LabelWithNoBarProps = {
  children: ReactNode
  textColor?: string
}

export const LabelWithNoBar: FC<LabelWithNoBarProps> = ({ children, textColor = "text-gray-400" }) => {
  return (
    <div className="w-full">
      <div className={classNames("rounded h-6 flex items-center px-3 bg-gray-100 font-bold text-[13px]", textColor)}>
        {children}
      </div>
    </div>
  )
}
