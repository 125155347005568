import { Box } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect, useState } from "react"
import { useQuery } from "urql"
import {
  Asset,
  AssetInspectionTemplateAssignments,
  AssetReportTemplate,
} from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { PickPlus } from "../../../../types/helpers"
import { DropDownMUI, DropDownMUIItem } from "../../../DropDownMUI"
import { DatePicker } from "../../../Formik/DatePicker"
import { Switch } from "../../../Formik/Switch"
import { InventoryIntervalOptions } from "./InventoryIntervalOptions"

type InspectionTemplatesAssignments = (PickPlus<
  AssetInspectionTemplateAssignments,
  "id" | "intervalInSeconds" | "lastReportedAt" | "startDate"
> & { inspectionTemplate: PickPlus<AssetReportTemplate, "id" | "name"> })[]

type AssetExpectation = PickPlus<Asset, "active"> & {
  inspectionTemplatesAssignments: InspectionTemplatesAssignments
}

const AssetInspectionFormQueryDocument = graphql(`
  query AssetInspectionForm {
    reusableAssetReportTemplates {
      id
      name
      universal
    }
  }
`)

export const AssetInspectionForm: FC<{ asset: AssetExpectation | undefined }> = ({ asset }) => {
  const [{ data, error: reusableTemplatesError }] = useQuery({ query: AssetInspectionFormQueryDocument })
  useHandleError(reusableTemplatesError, "There was an error with Asset Inspection")

  const [options, setOptions] = useState<DropDownMUIItem[][] | undefined>(undefined)
  const [switchField, _switchMeta, switchHelpers] = useField("inspectionRequirements.inspectionRequired")
  const [_templateField, _templateMeta, templateHelpers] = useField("inspectionRequirements.assetReportTemplateId")

  useEffect(() => {
    if (data) {
      setOptions(() => {
        const opts = []
        const orgTemplates = data?.reusableAssetReportTemplates
          .filter((template) => !template.universal)
          .map((template) => ({ value: template.id, label: template.name }))
        const universalTemplates = data.reusableAssetReportTemplates
          .filter((template) => template.universal)
          .map((template) => ({ value: template.id, label: template.name }))

        if (orgTemplates.length) opts.push(orgTemplates)
        if (universalTemplates.length) opts.push(universalTemplates)
        if (!opts.length) opts.push([{ value: "", label: "No Templates", disabled: true }])

        return opts
      })
    }
  }, [data])

  useEffect(() => {
    if (!switchField.value) templateHelpers.setValue("")
  }, [switchField.value, templateHelpers])

  useEffect(() => {
    const firstReportTemplateId = asset?.inspectionTemplatesAssignments?.[0]?.inspectionTemplate?.id

    if (firstReportTemplateId) {
      switchHelpers.setValue(true)
      templateHelpers.setValue(firstReportTemplateId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset?.inspectionTemplatesAssignments, options])

  const frequencyOptions: DropDownMUIItem[] = [
    { value: "none", label: "None", color: "text-gray-400" },
    ...Object.keys(InventoryIntervalOptions).map((key) => ({
      value: InventoryIntervalOptions[key].toString(),
      label: key,
    })),
  ]

  // Conditionally rendering to asynchronously set the default value for assetInspectionTemplateId
  // If there is a better way, we can change it
  if (!options) return null

  return (
    <div className="flex-col">
      <div className="flex mb-6">
        <Switch name="inspectionRequirements.inspectionRequired" label="Activate inspection" />
      </div>
      {switchField.value && (
        <div className="p-5 border rounded flex-col">
          <DropDownMUI
            items={options.flat()}
            fieldName="inspectionRequirements.assetReportTemplateId"
            label="Choose Inspection Template"
          />
          <div className="flex justify-between mt-4">
            <Box width="50%" marginRight={2}>
              <DropDownMUI
                items={frequencyOptions}
                fieldName="inspectionRequirements.intervalInSeconds"
                label="Select frequency"
              />
            </Box>
            <Box width="50%">
              <DatePicker name="inspectionRequirements.startDate" label="Start date" className="w-full" />
            </Box>
          </div>
        </div>
      )}
    </div>
  )
}
