import { FormControlLabel, FormGroup, Switch as MuiSwitch } from "@mui/material"
import { useField } from "formik"
import { FC } from "react"

type SwitchProps = {
  label?: string
  name: string
}

export const Switch: FC<SwitchProps> = ({ label, name }) => {
  const [field, _meta, helpers] = useField(name)

  return (
    <FormGroup>
      <FormControlLabel
        onChange={() => helpers.setValue(!field.value)}
        control={<MuiSwitch checked={field.value} />}
        label={label}
        value={field.value}
      />
    </FormGroup>
  )
}
