import { FC } from "react"
import { AiOutlineWarning } from "react-icons/ai"
import { BiX } from "react-icons/bi"
import { classNames } from "../helpers/classNames"
import { ProgressBar } from "./ProgressBars/ProgressBar"

type ImageUploadPreviewProps = {
  imageSrc: string
  progress?: number
  error?: boolean
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
}

export const ImageUploadPreview: FC<ImageUploadPreviewProps> = ({
  imageSrc,
  onDelete,
  progress,
  error = false,
  className,
}) => {
  const loading = (!!progress || progress === 0) && progress < 1

  return (
    <div className={classNames(classNames("relative grow aspect-square", className))}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={imageSrc}
        alt="Uploaded file"
        className={classNames("object-cover rounded-md", loading && "opacity-40")}
      />
      {error && (
        <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full">
          <AiOutlineWarning className="text-red-500 h-10 w-10" />
        </div>
      )}
      {onDelete && (
        <button
          type="button"
          className="absolute top-0.5 right-0.5 flex items-center justify-center rounded-full bg-white text-gray-800 shadow h-5 w-5"
          onClick={(event) => {
            event.preventDefault()
            onDelete(event)
          }}
        >
          <BiX className="w-3 h-3" />
        </button>
      )}
      {loading && !error && (
        <div className="absolute bottom-1 w-full px-2">
          <ProgressBar percentage={progress} suppressPercentLabel={true} className="h-1 py-[3px]" />
        </div>
      )}
    </div>
  )
}
