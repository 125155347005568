import { useField } from "formik"
import { FC, InputHTMLAttributes } from "react"
import { classNames } from "../../helpers/classNames"
import { Label } from "../Elements"
import Checkbox from "../Elements/Checkbox"

type Props = {
  name: string
  containerClassName?: string
  label?: string
  labelClassName?: string
  required?: boolean
  tooltipLabel?: string
  tooltipText?: string
}

export const StandardCheckbox: FC<InputHTMLAttributes<HTMLInputElement> & Props> = ({
  name,
  containerClassName,
  disabled,
  label,
  labelClassName,
  required = true, // Maybe not the expected default but consistent w/ current behavior
  tooltipLabel,
  tooltipText,
}) => {
  const [field, meta, helpers] = useField({ name, type: "checkbox" })

  return (
    <div className={containerClassName}>
      <div className={classNames(`flex items-center flex-wrap gap-2`, disabled && `opacity-40`)}>
        <Checkbox
          checked={!!field.value}
          onChange={() => helpers.setValue(!field.value)}
          className={`focus:ring-0 focus:shadow-none focus:ring-offset-0 ${
            meta.touched && meta.error ? `border-red-600 border-2` : "border m-px"
          }`}
        />
        <div className="flex justify-between flex-wrap items-start">
          <Label
            htmlFor={field.name}
            isRequired={required}
            tooltipText={tooltipText}
            tooltipLabel={tooltipLabel}
            className={labelClassName}
          >
            {label}
          </Label>
        </div>
      </div>
      {meta.touched && meta.error && <div className="text-red-600 text-sm">{meta.error}</div>}
    </div>
  )
}
