import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../helpers/classNames"

type Props = {
  labelClassName?: string
  textLabel?: ReactNode
  textLabelClassName?: string
  childrenContainerClassName?: string
  children: ReactNode
  isRequired?: boolean
  overrideStyling?: boolean
}

export const FormRow: FC<Props> = ({
  textLabel,
  children,
  textLabelClassName,
  labelClassName,
  childrenContainerClassName,
  isRequired,
  overrideStyling = false,
}) => {
  return (
    <label
      className={overrideTailwindClasses(
        classNames(overrideStyling ? "" : "grid md:grid-cols-12 gap-y-1 md:gap-y-2 mb-4", labelClassName)
      )}
    >
      {textLabel && (
        <div
          className={overrideTailwindClasses(
            classNames("md:mt-2 md:col-span-5 uppercase font-bold text-xs", textLabelClassName)
          )}
          onClick={(e) => e.preventDefault()}
        >
          {textLabel}
          {textLabel && isRequired && <span className={"text-gray-400"}> *</span>}
        </div>
      )}
      <div
        className={overrideTailwindClasses(classNames("col-span-12 md:col-span-7 md:flex", childrenContainerClassName))}
      >
        {children}
      </div>
    </label>
  )
}
