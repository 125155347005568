import * as Yup from "yup"
import { InventoryIntervalOptions } from "./CreateOrEditAssetForm/InventoryIntervalOptions"

export const InventoryRequirementsValidationSchema = Yup.object().shape({
  intervalInSeconds: Yup.string().oneOf(Object.values(InventoryIntervalOptions).map((n) => n.toString())),
  photoRequired: Yup.boolean(),
})

export const InspectionRequirementsValidationSchema = Yup.object().shape({
  intervalInSeconds: Yup.string()
    .oneOf(["", "none", ...Object.values(InventoryIntervalOptions).map((n) => n.toString())])
    .nullable()
    .when("inspectionRequired", { is: true, then: (schema) => schema.required("Required") }),
  startDate: Yup.string().nullable(),
  inspectionRequired: Yup.boolean(),
  assetReportTemplateId: Yup.string().when("inspectionRequired", {
    is: true,
    then: (schema) => schema.required("Required"),
  }),
})
